import {React} from 'react';
import { Container, Nav, Navbar, } from 'react-bootstrap';
import '../styles/home.css';
import DarkMode from './themeToggle.js';


function Navigation() {
    return (
        <Navbar collapseOnSelect expand="lg" className='nav_theme' fixed='top'>
  <Container>
  <Navbar.Brand className='nav_theme_brand' href="/">PranshuTech</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav nav" className='nav_theme_brand' />
  <Navbar.Collapse id="responsive-navbar-nav" className='nav_theme_brand'>
    <Nav className="me-auto nav nav_theme_brand">
      <Nav.Link className='nav_theme_link' href="/about">About</Nav.Link>
      {/* <Nav.Link className='nav_theme_link' href="/works">My Works</Nav.Link>
        <NavDropdown.Divider />
        <NavDropdown.Item href="https://www.youtube.com/@pranshugahlawat3720" target='_blank'>Tutorials</NavDropdown.Item>
        <NavDropdown.Item href="/tutorials">Tutorials</NavDropdown.Item> */}
    </Nav>
    <Nav className='nav'>
      <Nav.Link className='nav_theme_link' href="/contact">Contact</Nav.Link>
      <DarkMode/>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
    );
}

export default Navigation