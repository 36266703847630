import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/Navigation';
import About from './pages/about';
import Contact from './pages/contact';
import Home from './pages/home';
// import Tutorials from './pages/tutorials';
import ProjectEnquiry from './pages/projectEnquiry';

function App() {
  return (
    <div className="App">
                <Navigation/>
      <BrowserRouter>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    {/* <Route path='/tutorials' elnement={<Tutorials/>}/> */}
                    <Route path='/enquiry' element={<ProjectEnquiry/>}/>
                    <Route path='*' element={<Home/>}/>
                </Routes>
            </BrowserRouter>
        </div>
  );
}

export default App;
