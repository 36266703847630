import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/contact.css';
import { Grid, Button, Input } from '@nextui-org/react';
import React, { useState } from 'react';


const ProjectEnquiry = () => {
  const {
    handleSubmit,
  } = useForm();

  const [Budget, setBudget] = useState("");
  const [Name, setName] = useState("");
  const [TOP, setTOP] = useState("");
  const [AI, setAI] = useState("");
  const [Email, setEmail] = useState("");

  const reset = () => {
    setName("");
    setEmail("");
    setTOP("");
    setAI("");
    setBudget("");
  }

  const email = Email
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const Success = () => {
    return alert("Form Submission is successfull")
  };
  
  const onSubmit = async() => {
    if(!Email || !Name || !TOP || !Budget){
      return alert("All fields are required")}
      if(!emailRegex.test(email)){
        return alert("Please enter a valid email address")
      }
      else{
    try {
    
      const templateParams = {
        name: Name,
        email: Email,
        top: TOP,
        budget: Budget,
        additional_info: AI,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        "template_f35arma",
        templateParams,
        process.env.REACT_APP_USER_ID,
      );

      Success();
      reset();
    } catch (e) {
      console.log(e);
    }
  }};
  return (
    <section>
            <form  onSubmit={handleSubmit(onSubmit)} noValidate>
            <h1 className='h1'>Enthusiastic to work with you, Let's do it!</h1>
            <div className='container-form'>
              <Grid.Container gap={3} className='container2'>
                <Grid className='Grid'>
                <Input
                required
                className='input'
                width='15vw'
                status='success'
                  value={Name}
                  labelPlaceholder="Name"
                  onChange={e => setName(e.target.value)}
                  rounded
                  maxLength={30}
                />
                </Grid><Grid className='Grid'>
                <Input
                required
                className='input'
                width='25vw'
                  rounded
                  shadow={false}
                  status='success'
                  type="email"
                  maxLength={35}
                  labelPlaceholder='Email'
                  value={Email}
                  onChange={e =>{setEmail(e.target.value)}}
                /></Grid><Grid className='Grid'>
                <Input
                className='input'
                width='30vw'
                status='success'
                  value={AI}
                  labelPlaceholder="Additional Information"
                  onChange={e => setAI(e.target.value)}
                  rounded
                /></Grid>
                    </Grid.Container><Grid.Container className='container2'>
                    <Grid className='Grid'><div className='form-group'>
                      <label for="top" className='select-label'>Type of Project:</label><br/>
                      <select value={TOP} onChange={e => setTOP(e.target.value)} defaultValue={""} name='top' className='select' required>
                      <option value={""} disabled selected>--- Select ---</option>
                        <option value={'Website'}>Website</option>
                        <option value={'Application'}>Application</option>
                        <option value={'Robot Build'}>Robot Build</option>
                        <option value={'Robot Design'}>Robot Design</option>
                        <option value={'Robot Programming'}>Robot Programming</option>
                        <option value={'Other'}>Other</option>
                      </select>
                    </div></Grid><Grid className='Grid'>
                    <div className='form-group'>
                      <label for='budget' className='select-label'>Budget</label><br/>
                      <select value={Budget} required
          onChange={e => setBudget(e.target.value)} name='Budget' defaultValue={""} className='select'>
            <option value={""} disabled selected>--- Select ---</option>
                        <option value={'0-100'}>Less than $100</option>
                        <option value={'100-500'}>$100-$500</option>
                        <option value={'500-1000'}>$500-$1000</option>
                        <option value={'1000+'}>Above $1000</option>
                      </select>
                    </div></Grid><Grid className='Grid'>
                <Button className='btn' size="xl" color="gradient" ghost type='submit'>Let's Begin the Work!</Button>
                </Grid>
                    </Grid.Container>
                    </div>
              </form>
               </section>
  );
};

export default ProjectEnquiry;