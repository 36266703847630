import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/contact.css';
import { Grid, Button, Input, Textarea } from '@nextui-org/react';
import React, { useState } from 'react';

const Contact = () => {
  const {
    handleSubmit,
  } = useForm();

  const [Name, setName] = useState("");
  const [Msg, setMsg] = useState("");
  const [Subject, setSubject] = useState("");
  const [Email, setEmail] = useState("");

  const reset = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMsg("");
  }

  const Success = () => {
    return alert("Form Submission is successfull")
  };
  const email = Email
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const onSubmit = async () => {
    if(!Email || !Name || !Msg || !Subject){
      return alert("All fields are required")}
      if(!emailRegex.test(email)){
        return alert("Please enter a valid email address")
      }
      else{
    try {
      const templateParams = {
        name: Name,
        email: Email,
        message: Msg,
        subject: Subject,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        "template_c9eikxy",
        templateParams,
        process.env.REACT_APP_USER_ID,
      );

      Success();
      reset();
    } catch (e) {
      console.log(e);
    }
  }}
  return (
    <section>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <h1 className='h1'>Exited to hear from you, How may I help you?</h1>
                    <div className='container-form'>
              <Grid.Container gap={3} className='container2'>
                <Grid className='Grid'>
                <Input
                required
                className='input'
                width='15vw'
                status='success'
                  value={Name}
                  labelPlaceholder="Name"
                  onChange={e => setName(e.target.value)}
                  rounded
                  onReset={e => setName("")}
                  maxLength={30}
                />
                </Grid><Grid className='Grid'>
                <Input
                required
                className='input'
                width='20vw'
                  rounded
                  status='success'
                  type="email"
                  maxLength={35}
                  labelPlaceholder='Email'
                  value={Email}
                  onChange={e =>{setEmail(e.target.value)}}
                /></Grid><Grid className='Grid'>
                <Input
                  className='input'
                  width='25vw'
                  status='success'
                  value={Subject}
                  labelPlaceholder="Subject"
                  onChange={e => setSubject(e.target.value)}
                  rounded
                /></Grid>
                    </Grid.Container><Grid.Container className='container2'>
                    <Grid className='Grid'><div className='form-group'>
                    <Textarea className='input2' width='30vw' labelPlaceholder="Message" status='success' required minRows={3} maxRows={6} onChange={e => setMsg(e.target.value)} value={Msg}/>
                    </div></Grid><Grid className='Grid'>
                <Button className='btn' size="xl" color="gradient" ghost type='submit'>Let's Begin the Work!</Button>
                </Grid>
                    </Grid.Container>
                    </div>
              </form>
               </section>
  );
};

export default Contact;