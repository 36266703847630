import { Button, Card, Col, Grid, Row, Text } from '@nextui-org/react';
import React from 'react';
// import bg from '../assets/videos/bg.mp4';
import '../styles/home.css';
import onshape from '../assets/images/onshape.png'
import RoboAvenger from '../assets/images/RoboAvenger.png'
import IRC from '../assets/images/IRC.png'
import ICDPC from '../assets/images/ICDPC.png'
import FTC2122 from '../assets/images/FTC2122.png'
import FTC2223 from '../assets/images/FTC2223.png'
import { BsGithub } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsFillSendFill } from "react-icons/bs";

function Home() {
  return (
    <>
    <div className="main">
        {/* <div className='overlay'>
    <video autoplay loop muted>
    <source src={bg} type='video/mp4'/>
    </video></div>  */}
    <div className='content'> 
        <h1><b>Hey!</b> there Its <span className='sp1'>T</span>echnical <span className='sp1'>P</span>ranshu <span className='sp1'>G</span>ahlawat</h1>
        <h3>I am a Young Developer/Programmer at age of <span className='sp2'>15</span> with expertise in working with Web Development, HTML, CSS, JS, PHP, A-frame, Python, Java, OpenCV, Robotics, IoT, ML, etc. I wrote my <span className='sp2'>1st</span> code at age of <span className='sp2'>10</span>. I aim to make a difference through my creative solution.</h3>
        </div></div>
        <div className='content2'>
        <h2>Startups Associated</h2>
        <Grid.Container alignItems='center' className='startup-card'>
      <Grid className='Grid'>
        <Card css={{ mw: "330px" }}>
          <Card.Header>
            <Text b>Programming Club</Text>
          </Card.Header>
          <Card.Divider />
          <Card.Body css={{ py: "$10" }}>
            <Text>
            It aims at identifying and developing innovative skills with assured guidance and proper mentoring to make them future innovators, technology players and entrepreneurs.
            </Text>
          </Card.Body>
          <Card.Divider />
          <Card.Footer>
            <Row justify="center">
              <Button size="sm" color="secondary">
              <a className="a" target="_blank" rel="noreferrer" href="https://programmingclub.in">Learn More</a>
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      </Grid>

      <Grid className='Grid'>
        <Card css={{ mw: "330px" }}>
          <Card.Header>
            <Text b>STEAM Vision Foundation</Text>
          </Card.Header>
          <Card.Divider />
          <Card.Body css={{ py: "$10" }}>
            <Text>
            A not-for-profit organization on a mission to spread the vision of STEAM for inspiring the next generation of innovators. 
            </Text>
          </Card.Body>
          <Card.Divider />
          <Card.Footer>
            <Row justify="center">
              <Button size="sm" color="secondary">
              <a className="a" target="_blank" rel="noreferrer" href="https://steamvisionfoundation.org">Learn More</a>
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      </Grid>
      <Grid className='Grid'>
        <Card css={{ mw: "330px" }}>
          <Card.Header>
            <Text b>Study in Real</Text>
          </Card.Header>
          <Card.Divider />
          <Card.Body css={{ py: "$10" }}>
            <Text>
              A Platform providing students to visualize their concepts in 3d. And also provide regular quiz, notes, etc.
            </Text>
          </Card.Body>
          <Card.Divider />
          <Card.Footer>
            <Row justify="center">
              <Button size="sm" color="secondary">
                <a className="a" target="_blank" rel="noreferrer" href="https://studyinreal.com">Learn More</a>
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      </Grid>
    </Grid.Container>
        </div>
        <div className='content3'>
          <Grid.Container className='grid-container-c3'>
        <Grid className='Grid2' >
        <Card className='card-c3' css={{ mw: "330px"}}>
          <Card.Header>
            <Text size="20px" color="white" b>Hours of Code</Text>
          </Card.Header>
          <Card.Body css={{ py: "$10" }}>
            <Text size='30px' color="rgb(255, 217, 66)" b>
              5000+
            </Text>
          </Card.Body>
        </Card>
      </Grid>
      <Grid className='Grid2' >
      <Card className='card-c3' css={{ mw: "330px"}}>
          <Card.Header>
            <Text size="20px" color="white" b>Projects</Text>
          </Card.Header>
          <Card.Body css={{ py: "$10" }}>
            <Text size='30px' color="rgb(255, 217, 66)" b>
              100+
            </Text>
          </Card.Body>
        </Card>
      </Grid><Grid className='Grid2' >
      <Card className='card-c3' css={{ mw: "330px"}}>
          <Card.Header>
            <Text size="20px" color="white" b>Experience</Text>
          </Card.Header>
          <Card.Body css={{ py: "$10" }}>
            <Text size='30px' color="rgb(255, 217, 66)" b>
              5+ years
            </Text>
          </Card.Body>
        </Card>
      </Grid><Grid className='Grid2' >
      <Card className='card-c3' css={{ mw: "330px"}}>
          <Card.Header>
            <Text size="20px" color="white" b>Proficent Skills</Text>
          </Card.Header>
          <Card.Body css={{ py: "$10" }}>
            <Text size='30px' color="rgb(255, 217, 66)" b>
              50+
            </Text>
          </Card.Body>
        </Card>
      </Grid></Grid.Container>
        </div>
        <div className='content4'>
        <h2>Achievements</h2>
    <div className='achievement'>
      <Grid.Container gap={4}>
    <Grid className='grid'><Card className='achievecard' css={{ bg: "$black", w: "25vw" }}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
      <Col>
        <Text h4 color="gold">
          Ranked 51st in Design Competition
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={onshape}
      className='card'
    />
  </Card></Grid><Grid className='grid'>
  <Card className='achievecard' css={{ bg: "$black", w: "25vw" }}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: 80, left: 0 }}>
      <Col>
        <Text h4 color="gold">
          Successfully Completed E-bootcamp on Robotics
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={RoboAvenger}
      className='card'
    />
  </Card></Grid><Grid className='grid'><Card className='achievecard' css={{ bg: "$black", w: "25vw" }}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: 150, left:-120 }}>
      <Col>
        <Text h4 color="gold">
          Participated in IRC
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={IRC}
      className='card'
    />
  </Card></Grid><Grid className='grid'><Card className='achievecard' css={{ bg: "$black", w: "25vw" ,}}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: -3 }}>
      <Col>
        <Text h4 color="gold">
          Attended International Conference on Cyber World
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={ICDPC}
      className='card'
    />
  </Card></Grid><Grid className='grid'><Card className='achievecard' css={{ bg: "$black", w: "25vw" }}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: 40 }}>
      <Col>
        <Text h4 color="gold">
          Participated in FTC 2021-22
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={FTC2122}
      className='card'
    />
  </Card></Grid><Grid className='grid'><Card className='achievecard' css={{ bg: "$black", w: "25vw" }}>
  <Card.Header css={{ position: "absolute", zIndex: 1, top: -2 }}>
      <Col>
        <Text h4 color="gold">
          Won Young Achievers Award at FTC
        </Text>
      </Col>
    </Card.Header>
    <Card.Image
      src={FTC2223}
      className='card'
    />
  </Card></Grid></Grid.Container>
  </div>
        </div>
        <div className='content5'>
        <div className='content5-1'>
          <h2 className='start-project'>Start a Project!</h2>
          <h4 className='interested'>Interested in working together? We should queue up a time to chat.</h4>
          <Button className='enquiry' color="gradient" ghost rounded auto><a href='/enquiry' className='enquiry-a'>Start Enquiry</a></Button>
        </div>
        <div className='social'>
          <a className='icon a' href="mailto:pranshugahlawat@gmail.com" target={'_blank'} rel="noreferrer" ><BsFillSendFill size='3vh' className='icon-a'/></a>
          <a className='icon a'  href="https://github.com/metpranshug" rel="noreferrer" target={'_blank'}><BsGithub size='3vh' className='icon-a'/></a>
          <a className='icon a' href="https://instagram.com/metpranshug" rel="noreferrer" target={'_blank'}><BsInstagram size='3vh' className='icon-a'/></a>
          <a className='icon a' href="https://www.linkedin.com/in/metpranshug/" rel="noreferrer" target={'_blank'}><BsLinkedin size='3vh' className='icon-a' color='48b4ff'/></a>
          <a className='icon a' href="https://www.youtube.com/@pranshugahlawat3720" rel="noreferrer" target={'_blank'}><BsYoutube size='3vh' className='icon-a' color='ff0000'/></a>
        </div>
        </div>
    </>
  )
}

export default Home;