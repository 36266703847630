import { Card, Grid } from '@nextui-org/react';
import React from 'react'
import '../styles/about.css'
import Typewriter from "typewriter-effect";
import TPG from '../assets/images/TPG.png'

function About() {
  return (
    <div >
    <div className='about1'>
      <Grid.Container className='container'>
        <Grid className='img'><img alt="" src={TPG}/></Grid>
        <Grid className='content-grid'>
          <h1>
            <b>
              Its </b>
            <span className='sp1'>
              P
            </span>
            ranshu <span className='sp1'>
              G
            </span>
            ahlawat a <span className='sp1 type-writer'><Typewriter options={{
    strings: ['Student', 'Youtuber', 'Developer', 'Coder', 'Video Creator', 'Designer', 'Application Developer', 'Software Developer', 'Intern', 'Founder', 'Software Engineer', 'Roboticist'],
    autoStart: true,
    loop: true,}} /></span>
          </h1>
          <h4 className='h4'>I am a 10th Standard Student from Panipat, Haryana. I have been quite interested in Robotics and Computer Science since grade 5th. And today I stand at a position of a Full-Stack Web Developer, Software Developer. I also have attained experience of creating eCommerce Websites. And my experience gained from working with AI, ML, CAD Designing, UI/UX, Automation, Robotics, etc. can also not be ignored. And I wish to share that recently I also participated in a hackathon organized by Avishkaar and Our Team bagged 10th Position there. I am also a participant of FIRST Tech Challenge twice in 2021-22, 2022-23 held at Pune, Maharashtra and our team got into the top few finalists and got Young Achievers Award. I am a Founding Intern at an EdTech Startup of B.Tech Students and working there as Web and 3d Developer. I aim to Pursue my Passion in Robotics and Computer Science in upcomming future as Well.</h4>
        </Grid>
      </Grid.Container>
    </div>
    <div className='about2'>
      <h1 className='featured-h'>Featured Skills</h1>
      <div className='about2-1'>
      <Grid.Container gap={4} direction='column' className='container-featured'>
      <Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              HTML
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle1' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>60+ Projects<br/><br/>100+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              CSS
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle2' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>40+ Projects<br/><br/>80+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              React
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle3' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>60+ Projects<br/><br/>130+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              Node
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle4' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>15+ Projects<br/><br/>30+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              Dart
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle5' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>60+ Projects<br/><br/>150+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              Python
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle6' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>100+ Projects<br/><br/>210+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              JS
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle7' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>70+ Projects<br/><br/>160+ Hours</h3></div>
      </Grid></Grid.Container>
      <Grid.Container className='container-featured' direction='column' gap={4}>
      <Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              PHP
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle8' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>50+ Projects<br/><br/>90+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              Java
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle9' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>50+ Projects<br/><br/>70+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              AI
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle10' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>20+ Projects<br/><br/>40+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              ML
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle11' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>30+ Projects<br/><br/>50+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              Robotics
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle12' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>150+ Projects<br/><br/>400+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              CAD
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle13' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>100+ Projects<br/><br/>150+ Hours</h3></div>
      </Grid><Grid className='grid-featured'><div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div id="number">
              UI/UX
            </div>
          </div>
        </div>
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
         <defs>
            <linearGradient id="GradientColor">
               <stop offset="0%" stop-color="#e91e63" />
               <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
         </defs>
         <circle className='circle14' cx="80" cy="80" r="70" stroke-linecap="round" />
 </svg>
      </div>
      <div className='featured-content'><h3 className='h3'>120+ Projects<br/><br/>200+ Hours</h3></div>
      </Grid></Grid.Container>
    </div>
    </div>
    <div className='about3'>
      <h1 className='featured-h2'>Education</h1>
      <Grid.Container justify='center' className='ed-container'>
        <Card variant='bordered' borderWeight='bold' className='ed-card'>
          <Card.Header><b>School</b></Card.Header>
          <Card.Body>DAV Public School, Panipat</Card.Body>
        </Card>
        <Card variant='bordered' borderWeight='bold' className='ed-card'>
          <Card.Header><b>Internship</b></Card.Header>
          <Card.Body>Study in Real</Card.Body>
          <Card.Footer>2022-Present</Card.Footer>
        </Card>
        <Card variant='bordered' borderWeight='bold' className='ed-card'>
          <Card.Header><b>Internship</b></Card.Header>
          <Card.Body>Programming Club</Card.Body>
          <Card.Footer>2019-Present</Card.Footer>
        </Card>
      </Grid.Container>
    </div>
    </div>
  )}

export default About;